.background-1 {
  background-image: radial-gradient(
      circle at 33% 41%,
      rgba(250, 250, 250, 0.03) 0%,
      rgba(250, 250, 250, 0.03) 50%,
      rgba(37, 37, 37, 0.03) 50%,
      rgba(37, 37, 37, 0.03) 100%
    ),
    radial-gradient(
      circle at 76% 49%,
      rgba(28, 28, 28, 0.03) 0%,
      rgba(28, 28, 28, 0.03) 50%,
      rgba(187, 187, 187, 0.03) 50%,
      rgba(187, 187, 187, 0.03) 100%
    ),
    radial-gradient(
      circle at 41% 99%,
      rgba(247, 247, 247, 0.03) 0%,
      rgba(247, 247, 247, 0.03) 50%,
      rgba(120, 120, 120, 0.03) 50%,
      rgba(120, 120, 120, 0.03) 100%
    ),
    radial-gradient(
      circle at 66% 27%,
      rgba(17, 17, 17, 0.03) 0%,
      rgba(17, 17, 17, 0.03) 50%,
      rgba(156, 156, 156, 0.03) 50%,
      rgba(156, 156, 156, 0.03) 100%
    ),
    linear-gradient(191deg, rgb(20, 25, 46), rgb(26, 32, 60));
}

.background-2 {
  background-image: radial-gradient(
      circle at 19% 90%,
      rgba(190, 190, 190, 0.04) 0%,
      rgba(190, 190, 190, 0.04) 17%,
      transparent 17%,
      transparent 100%
    ),
    radial-gradient(
      circle at 73% 2%,
      rgba(78, 78, 78, 0.04) 0%,
      rgba(78, 78, 78, 0.04) 94%,
      transparent 94%,
      transparent 100%
    ),
    radial-gradient(
      circle at 45% 2%,
      rgba(18, 18, 18, 0.04) 0%,
      rgba(18, 18, 18, 0.04) 55%,
      transparent 55%,
      transparent 100%
    ),
    radial-gradient(
      circle at 76% 60%,
      rgba(110, 110, 110, 0.04) 0%,
      rgba(110, 110, 110, 0.04) 34%,
      transparent 34%,
      transparent 100%
    ),
    radial-gradient(
      circle at 68% 56%,
      rgba(246, 246, 246, 0.04) 0%,
      rgba(246, 246, 246, 0.04) 16%,
      transparent 16%,
      transparent 100%
    ),
    radial-gradient(
      circle at 71% 42%,
      rgba(156, 156, 156, 0.04) 0%,
      rgba(156, 156, 156, 0.04) 47%,
      transparent 47%,
      transparent 100%
    ),
    radial-gradient(
      circle at 46% 82%,
      rgba(247, 247, 247, 0.04) 0%,
      rgba(247, 247, 247, 0.04) 39%,
      transparent 39%,
      transparent 100%
    ),
    radial-gradient(
      circle at 50% 47%,
      rgba(209, 209, 209, 0.04) 0%,
      rgba(209, 209, 209, 0.04) 45%,
      transparent 45%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(26, 32, 60), rgb(20, 25, 46));
}

.background-3 {
  background-image: radial-gradient(
      circle at 71% 29%,
      rgba(250, 250, 250, 0.03) 0%,
      rgba(250, 250, 250, 0.03) 32%,
      transparent 32%,
      transparent 100%
    ),
    radial-gradient(
      circle at 100% 82%,
      rgba(82, 82, 82, 0.03) 0%,
      rgba(82, 82, 82, 0.03) 26%,
      transparent 26%,
      transparent 100%
    ),
    radial-gradient(
      circle at 30% 35%,
      rgba(78, 78, 78, 0.03) 0%,
      rgba(78, 78, 78, 0.03) 55%,
      transparent 55%,
      transparent 100%
    ),
    radial-gradient(
      circle at 71% 54%,
      rgba(52, 52, 52, 0.03) 0%,
      rgba(52, 52, 52, 0.03) 13%,
      transparent 13%,
      transparent 100%
    ),
    radial-gradient(
      circle at 17% 19%,
      rgba(202, 202, 202, 0.03) 0%,
      rgba(202, 202, 202, 0.03) 83%,
      transparent 83%,
      transparent 100%
    ),
    radial-gradient(
      circle at 28% 30%,
      rgba(219, 219, 219, 0.03) 0%,
      rgba(219, 219, 219, 0.03) 39%,
      transparent 39%,
      transparent 100%
    ),
    radial-gradient(
      circle at 56% 19%,
      rgba(155, 155, 155, 0.03) 0%,
      rgba(155, 155, 155, 0.03) 33%,
      transparent 33%,
      transparent 100%
    ),
    linear-gradient(90deg, rgb(20, 25, 46), rgb(20, 25, 46));
}

.background-4 {
  background-image: linear-gradient(
      12deg,
      rgba(193, 193, 193, 0.05) 0%,
      rgba(193, 193, 193, 0.05) 2%,
      rgba(129, 129, 129, 0.05) 2%,
      rgba(129, 129, 129, 0.05) 27%,
      rgba(185, 185, 185, 0.05) 27%,
      rgba(185, 185, 185, 0.05) 66%,
      rgba(83, 83, 83, 0.05) 66%,
      rgba(83, 83, 83, 0.05) 100%
    ),
    linear-gradient(
      321deg,
      rgba(240, 240, 240, 0.05) 0%,
      rgba(240, 240, 240, 0.05) 13%,
      rgba(231, 231, 231, 0.05) 13%,
      rgba(231, 231, 231, 0.05) 34%,
      rgba(139, 139, 139, 0.05) 34%,
      rgba(139, 139, 139, 0.05) 71%,
      rgba(112, 112, 112, 0.05) 71%,
      rgba(112, 112, 112, 0.05) 100%
    ),
    linear-gradient(
      236deg,
      rgba(189, 189, 189, 0.05) 0%,
      rgba(189, 189, 189, 0.05) 47%,
      rgba(138, 138, 138, 0.05) 47%,
      rgba(138, 138, 138, 0.05) 58%,
      rgba(108, 108, 108, 0.05) 58%,
      rgba(108, 108, 108, 0.05) 85%,
      rgba(143, 143, 143, 0.05) 85%,
      rgba(143, 143, 143, 0.05) 100%
    ),
    linear-gradient(
      96deg,
      rgba(53, 53, 53, 0.05) 0%,
      rgba(53, 53, 53, 0.05) 53%,
      rgba(44, 44, 44, 0.05) 53%,
      rgba(44, 44, 44, 0.05) 82%,
      rgba(77, 77, 77, 0.05) 82%,
      rgba(77, 77, 77, 0.05) 98%,
      rgba(8, 8, 8, 0.05) 98%,
      rgba(8, 8, 8, 0.05) 100%
    ),
    linear-gradient(334deg, rgb(20, 25, 46), rgb(20, 25, 46));
}
