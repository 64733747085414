.coming-soon {
  position: relative;
  border-top: 1px solid $primary-color;
  border-bottom: 1px solid $primary-color;
  background-position: center;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 6rem 0rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 575.98px) {
    // background: #ffffff;
    height: unset;
    padding: 3rem 0rem;
  }

  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.75);
  }

  .content-container {
    position: relative;
    max-width: 750px;
    padding: 0 12px;
    @media (max-width: 1199.98px) {
      margin: auto;
    }
    @media (max-width: 767.98px) {
      max-width: 600px;
      flex-direction: column;
      align-items: center;
    }
  }

  .main-title {
    font-family: $font-family-3;
    font-size: 6rem;
    // color: $third-color;
    color: #fff;
    @media (max-width: 767.98px) {
      font-size: 5rem;
    }

    @media (max-width: 575.98px) {
      font-size: 4rem;
    }

    @media (max-width: 400.98px) {
      font-size: 3.25rem;
    }
  }

  .title {
    font-size: 3.25rem;

    @media (max-width: 767.98px) {
      font-size: 2.5rem;
    }

    @media (max-width: 575.98px) {
      font-size: 2rem;
    }
  }

  .social-container {
    @media (max-width: 991.98px) {
      max-width: 400px;
      margin: auto;
    }
  }
  .one-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: solid 2px #fff;
    // padding: 2rem 1.5rem 3rem 1.5rem;
    width: 160px;
    height: 240px;
    border-radius: 24px;
    margin: auto;
    cursor: pointer;
    transition: 0.6s ease-in-out all;
    margin-bottom: 2rem;
    &:hover {
      box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 1rem $primary-color, 0 0 0.4rem $primary-color,
        0 0 1.4rem $primary-color, inset 0 0 1.3rem $primary-color;
      img {
        transform: scale(1.05);
      }
    }
    @media (max-width: 575.98px) {
      width: 120px;
      height: 200px;
    }
  }

  .social-img {
    height: 80px;
    width: 80px;
    object-fit: scale-down;
    transition: 0.6s ease-in-out all;
    @media (max-width: 575.98px) {
      height: 60px;
      width: 60px;
    }
  }

  .social-name {
    padding-top: 1rem;
    font-size: 1.3rem;
    color: #fff;
  }

  .medium-link {
    color: #fff;
    text-decoration: underline;
    transition: $transition;
    font-weight: 600;
    &:hover {
      color: $primary-color;
    }
  }
  .email-link {
    color: #fff;
    color: $third-color;
    transition: $transition;
    font-weight: 600;
    &:hover {
      color: darken($third-color, 10%);
    }
  }
}
