.contact {
  position: relative;
  border-top: 2px solid $primary-color;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 6rem 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 575.98px) {
    // background: #ffffff;
    height: unset;
    padding: 3rem 0rem;
  }
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.75);
  }

  .content-container {
    position: relative;
    max-width: 750px;
    @media (max-width: 1199.98px) {
      margin: auto;
    }
    @media (max-width: 767.98px) {
      max-width: 600px;
      flex-direction: column;
      align-items: center;
    }
  }
  .input-container {
    input,
    textarea {
      width: 100%;
      padding: 0.9rem 0.75rem;
      font-size: 1.25rem;
      border-radius: 12px;
      transition: all 0.4s ease-in;
    }
    textarea {
      min-height: 200px;
    }
  }
  .law-container {
    display: flex;

    color: #ffffff;

    small {
      color: $red-color;
      opacity: 0;
    }
    &.error {
      small {
        opacity: 1;
        display: block;
      }
    }
  }
  .send-btn {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .one-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: solid 2px #fff;
    // padding: 2rem 1.5rem 3rem 1.5rem;
    width: 160px;
    height: 240px;
    border-radius: 24px;
    margin: auto;
    cursor: pointer;
    transition: 0.6s ease-in-out all;
    margin-bottom: 2rem;
    &:hover {
      box-shadow: 0 0 0.1rem #fff, 0 0 0.1rem #fff, 0 0 1rem $primary-color, 0 0 0.4rem $primary-color,
        0 0 1.4rem $primary-color, inset 0 0 1.3rem $primary-color;
      img {
        transform: scale(1.05);
      }
    }
    p {
      padding-top: 1rem;
      font-size: 1.3rem;
      color: #fff;
    }
    img {
      height: 80px;
      width: 80px;
      object-fit: scale-down;
      transition: 0.6s ease-in-out all;
    }
  }
}
