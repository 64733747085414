@import "./../../scss/config";
#lightbox {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.nordic-special-img {
    img {
      cursor: pointer;
    }
  }
  .prev,
  .next,
  .close {
    position: absolute;
    font-size: 2.5rem;
    color: $gray-color-1;
    cursor: pointer;
    transition: all 0.2 ease-in;
    &:hover {
      color: $gray-color-2;
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 767.98px) {
      font-size: 1.8rem;
    }

    //Extra small devices (portrait phones, less than 576px)
    @media (max-width: 575.98px) {
      font-size: 1.5rem;
    }
  }
  .close {
    top: 2%;
    left: 2%;
  }

  .prev {
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
  }
  .next {
    top: 50%;
    right: 2%;
    transform: translateY(-50%);
  }

  img {
    width: auto;
    height: auto;
    max-width: 900px;
    max-height: 95%;
    padding: 4px;
    object-fit: scale-down;
  }
}

@media (max-width: 900.98px) {
  #lightbox {
    img {
      max-width: 95%;
    }
  }
}
