.slides-home {
  .slide-img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
  }
  .slide-img {
    border-radius: 0.6rem;
    box-shadow: rgba(0, 0, 0, 0.35) 1px 1px 8px 0;
    width: 250px;
    height: 190px;
    text-align: center;
    object-fit: cover;

    @media (max-width: 767.98px) {
      width: 280px;
      height: 180px;
    }
  }
}
