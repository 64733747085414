.tippy-box {
  background: #000000;
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 6px 4px;
  max-width: 180px;
  font-size: 0.9rem;
  text-align: center;
}

.theme-fixed-background {
  position: relative;
  // background: url('../img-background/karriere-2-attachment-2.jpg') no-repeat
  //   center center/cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 300px;
  background-attachment: fixed;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid $primary-color;
  box-shadow: $main-box-shadow;
  border-bottom: 2px solid $primary-color;
  .has-overlay::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background-color: rgba(black, 0.1);
  }
  @media (max-width: 767.98px) {
    display: none;
  }
}

.theme-btn {
  border-radius: 24px;
  border: $primary-color 3px solid;
  padding: 8px 0.75rem;
  min-width: 200px;
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $transition;
  text-align: center;
  &:hover {
    background-color: $primary-color;
    color: #fff;
  }
}

.theme-btn-1 {
  border-radius: 24px;
  border: $primary-color 3px solid;
  padding: 8px 0.75rem;
  min-width: 200px;
  display: inline-block;
  font-size: 1.2rem;
  cursor: pointer;
  transition: $transition;
  background-color: $primary-color;
  text-align: center;
  color: #fff;
  &:hover {
    background-color: unset;
    color: #fff;
  }
}

.theme-link {
  color: $primary-color;
  transition: $transition;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    color: $primary-color;
  }
}
