// Footer
footer {
    background-color: $background-color-1;
    .z-container {
      max-width: 1400px;
      margin: auto;
      padding: 0 12px;
    }
    a {
      color: #fff;
      transition: $transition;
      &:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }
    p {
      color: $primary-color;
    }
  
    ul {
      li {
        padding-bottom: 0.2rem;
        color: #fff;
      }
    }
    .copyright {
      color: $primary-color;
    //   font-size: 0.9rem;
      transform: translateY(2px);
      text-align: center;
      @media (max-width: 450.98px) {
        font-size: 0.75rem;
      }
    }
    .created-by {
      opacity: 0;
      pointer-events: none;
      height: 2px;
      overflow: hidden;
    }
  }
  