.home-boxes {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0rem;
  color: #fff;
  h2 {
    text-align: center;
    padding-bottom: 1rem;
    color: $third-color;
    text-transform: uppercase;
    margin: auto;
    @media (max-width: 575.98px) {
      font-size: 1.8rem;
    }
  }
  hr {
    width: 100%;
    color: $primary-color;
    height: 2px;
    background-color: $primary-color;
    margin: auto;
    border: none;
    margin-top: 2rem;
    margin-bottom: 2rem;
    z-index: 200;
  }
  .text-container-2 {
    max-width: 750px;
    margin: auto;
    line-height: 1.8rem;
  }
  .col-md-6,
  .col-lg-4 {
    margin: 0 !important ;
    padding: 0 0.25rem;
  }
  .werte-title {
    padding-top: 0.75rem;
    font-size: 1.4rem;
  }
  .one-box {
    background-color: $background-color-2;
    box-shadow: $main-box-shadow;
    padding: 0.75rem 0.25rem;
    margin-bottom: 1rem;
    min-height: 450px;
    &.one-box-2 {
      min-height: 350px;
    }
    @media (max-width: 991.98px) {
      min-height: 350px;
    }
    img {
      width: 90px;
      height: 90px;
      object-fit: scale-down;
      z-index: 10;
    }
    h3 {
      font-size: 0.95rem;
    }
    .top-part {
      position: relative;
      width: 100%;
      height: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 0.25rem;
      .number {
        position: absolute;
        left: 0;
        color: $third-color;
        font-size: 1.4rem;
        top: -12px;
      }
    }

    hr {
      width: 60px;
      color: $primary-color;
      height: 3px;
      background-color: $primary-color;
      margin: auto;
      border: none;
      margin-bottom: 0.4rem;
    }
    .content {
      padding: 1rem 0.25rem;
      text-align: justify;
    }
  }
  .box-title {
    font-weight: 600;
    display: block;
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    font-family: $font-family-1;
  }
  .box-container-img {
    max-width: 900px;
    margin: auto;
  }
  .one-box-img {
    background-color: #fff;
    border: solid 1px $secondary-color;
    min-height: 500px;
    padding-top: 0rem;
    max-width: 400px;
    margin: auto;
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }
    h3 {
      font-size: 0.95rem;
    }
    .title {
      text-align: center;
      color: $blue-color;
      font-size: 1.6rem;
      font-weight: 600;
      padding-top: 0.5rem;
    }

    p {
      padding: 1rem 2%;
      text-align: center;
    }
  }
}
